import PropTypes from "prop-types";
import {useEffect} from "react";
import {useFormContext} from "react-hook-form";
import {FormGroup, Label} from "reactstrap";
import CheckboxFlag from "../CheckboxNullable";

const SplitInputAs = ({splitInputAsQty, setSplitInputAsQty, isDisabled}) => {
  const {
    formState: {errors},
    setValue,
    control,
    getValues,
  } = useFormContext();

  useEffect(() => {
    setValue("splitInputByQty", splitInputAsQty);
  }, [setValue, splitInputAsQty]);

  const checkboxValue = getValues("splitInputByQty");
  useEffect(() => {
    if (checkboxValue !== undefined) setSplitInputAsQty(checkboxValue);
  }, [getValues, setSplitInputAsQty, checkboxValue]);

  return (
    <FormGroup className="py-3 flex justify-evenly">
      <Label for="splitInputByQty" className="fw-normal !text-base">
        You have chosen to create a split, how would you like to define these?
      </Label>
      <CheckboxFlag
        id="splitInputByQty"
        name="splitInputByQty"
        control={control}
        error={errors.inputAs}
        type="radio"
        disabled={isDisabled}
        labels={{yes: "By quantity", no: " By percentage"}}
        flex
      />
    </FormGroup>
  );
};

SplitInputAs.propTypes = {
  splitInputAsQty: PropTypes.bool.isRequired,
  setSplitInputAsQty: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};
export default SplitInputAs;
